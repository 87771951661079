
































import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import FtSelect from "~/components/customSFUI/vue/src/components/molecules/FtSelect2/FtSelect2.vue";
import { TransformedCustomerAddress } from '~/modules/customer/composables/types';
import userAddressesGetters from '~/modules/customer/getters/userAddressesGetters';
import { Countries } from '~/composables';
import { CheckoutAddressForm, getInitialCheckoutAddressForm } from '~/helpers/checkout/address';
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import { merge } from "lodash-es";

export default defineComponent({
  name: 'UserShippingAddressesV1',
  components: {
    FtSelect,
    SkeletonLoader,
  },
  props: {
    currentAddressId: {
      type: Number,
      default: null,
    },
    value: {
      type: Boolean,
      required: true,
    },
    customerAddresses: {
      type: Array as PropType<TransformedCustomerAddress[]>,
      required: true,
    },
    address: {
      type: Object as PropType<CheckoutAddressForm>,
      default: getInitialCheckoutAddressForm(),
    },
    countries: {
      type: Array as PropType<Countries[]>,
      default: () => [],
    },
    shippingAllowCountries: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['handleSetCurrentAddress'],
  setup(props, { emit }) {
    const addressesDropdown = ref([]);
    const selectedAddress = ref(props.currentAddressId);

    const addressesWithCountryName = computed(() => props.customerAddresses
    .filter((address) => props.shippingAllowCountries.includes(address.country_code))
    .map((address) => ({
      id: address.id,
      firstName: userAddressesGetters.getFirstName(address),
      lastName: userAddressesGetters.getLastName(address),
      street: userAddressesGetters.getStreetName(address),
      streetNumber: userAddressesGetters.getApartmentNumber(address),
      postalCode: userAddressesGetters.getPostCode(address),
      city: userAddressesGetters.getCity(address),
      province: userAddressesGetters.getProvince(address) || '',
      country: userAddressesGetters.getCountry(address),
      company: userAddressesGetters.getCompany(address),
      phone: userAddressesGetters.getPhone(address),
      isDefault: userAddressesGetters.isDefault(address),
      isDefaultShipping: userAddressesGetters.isDefaultShipping(address),
      isDefaultBilling: userAddressesGetters.isDefaultBilling(address),
      building_name: userAddressesGetters.getBuildingName(address),
      unit_no: userAddressesGetters.getUnitNo(address),
      alt_telephone: userAddressesGetters.getAltTelephone(address),
      building_type: userAddressesGetters.getBuildingType(address),
      countryName: props.countries
        .find(({ id }) => id === address.country_code)
        ?.full_name_locale
        ?? props.address.country_code,
    })));

    const updSelectedAddress = () => {
      emit('handleSetCurrentAddress', props.customerAddresses.filter((address) => address.id === selectedAddress.value)[0]);
    };

    // return addressDtl object with value of uid, label
    const fetchAddressDtl = (address) => {
      let addressDtl = {};
      let addressLabel = '';
      addressDtl =  merge({}, addressDtl, { uid : address.id });

      addressLabel = address.firstName + ' ' + address.lastName + ', ' + (address.building_name && address.building_name + ', ') + 
      (address.streetNumber ? address.street + ' ' + address.streetNumber : address.street) + ', ' + (address.unit_no ? address.unit_no + ', ' : '') + 
      address.countryName + ', ' + address.postalCode;

      addressDtl =  merge({}, addressDtl, { label : addressLabel });

      return addressDtl;
    };

    const fetchAddressesDropdown = () => {
      addressesDropdown.value = [];
      addressesWithCountryName.value.forEach((address) => {
        addressesDropdown.value.push(fetchAddressDtl(address));
      });
    };

    watch(
    () => addressesWithCountryName.value,
      async (newAddressesWithCountryName) => {
        if (newAddressesWithCountryName != null && newAddressesWithCountryName !== undefined){
          fetchAddressesDropdown();
        } 
      },
    { deep:true, immediate: true },
    );

    watch(
    () => props.currentAddressId,
      async (newCurrentAddressId) => {
        if (newCurrentAddressId != null && newCurrentAddressId !== undefined){
          selectedAddress.value = newCurrentAddressId;
        } 
      },
    );

    return {
      addressesDropdown,
      selectedAddress,
      updSelectedAddress,
    };
  },
});
