<template>
    <div
      class="ft-select"
      :class="{
        'is-selected': value || placeholder,
        'is-required': required,
        'is-disabled': disabled,
        'is-invalid': !valid,
      }"
    >
        <div class="custom-dropdown">
            <div 
                :id="label"
                v-focus
                v-bind="$attrs"
                :value="value"
                :disabled="disabled"
                :aria-invalid="!valid"
                :aria-required="required"
                :aria-describedby="errorMessage ? `${label}-error` : null"
                class="dropdown-toggle" 
                :class="{ active: isDropdownOpen, disabled: disabled }" 
                @click="e => !disabled && toggleDropdown()"
            >
                <span class="dropdown-toggle-msg">{{ selectedOption }}</span>
                <span class="sf-chevron" :class="{'sf-chevron--top': isDropdownOpen}">
                    <span class="sf-chevron__bar sf-chevron__bar--left" />
                    <span class="sf-chevron__bar sf-chevron__bar--right" />
                </span>
            </div>
            
            <ul class="dropdown-menu" v-show="isDropdownOpen">
                <slot />
                <li 
                    v-if="emptyLabel"
                    :value="''" 
                    tabindex="0" 
                    @click="toggleHandler( {value: '', label: emptyLabel});"
                    :class="{selected: selectedOption == emptyLabel}"
                >
                    {{ emptyLabel }}
                </li>
            </ul>
        </div>
        <div class="sf-select__error-message" v-if="!valid">
            <transition name="sf-fade">
            <!-- @slot Custom error message of form select -->
            <slot name="errorMessage" v-bind="{ errorMessage }">
                <span
                :id="`${label}-error`"
                :class="{ 'display-none': valid }"
                aria-live="assertive"
                >
                {{ errorMessage }}
                </span>
            </slot>
            </transition>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted, onUnmounted, getCurrentInstance } from '@nuxtjs/composition-api';
import { focus } from "@storefront-ui/vue/src/utilities/directives";
import { willChange } from "@storefront-ui/vue/src/utilities/directives";
import FtSelectOption from "./_internal/FtSelectOption.vue";
import Vue from "vue";
  
Vue.component("FtSelectOption", FtSelectOption);

// Shared reference to track open dropdowns
const openDropdowns = [];

export default defineComponent({
    name: "FtSelectV2",
    directives: { focus, willChange },
    props: {
        label: {
            type: String,
            default: "",
        },
        required: {
            type: Boolean,
            default: false,
        },
        valid: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: "This field is not correct.",
        },
        value: {
            type: [String, Number],
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        selected: {
            type: [String, Number],
            default: "",
        },
        dropdownOption: {
            type: [Array, String],
            default: "",
        },
        emptyLabel: {
            type: String,
            default: "",
        },
    },
    created() {
        this.$on("toggle", this.toggleHandler);
        if (this.selected) {
            // if selected have value at created lifecycle, then set the dropdown list label based on the option selected
            this.selectedOption = this.dropdownOption.filter(arr => arr.uid == this.selected)[0].label;
        } 
    },
    methods: {
        toggleHandler(optionSelected) {
            this.selectedOption = optionSelected.label;
            this.toggleDropdown();
            this.changeHandler(optionSelected.value);
        },
        changeHandler(event) {
            this.$emit("input", event);
        },
    },

    setup(props) {
        const isDropdownOpen = ref(false);
        const selectedOption = ref(props.emptyLabel || 'Select an option');
        const instance = getCurrentInstance();

        const toggleDropdown = () => {
            isDropdownOpen.value = !isDropdownOpen.value;
            if (isDropdownOpen.value) {
                 // Close other open dropdowns
                openDropdowns.forEach(dropdown => {
                    if (dropdown !== instance.proxy) {
                        dropdown.isDropdownOpen = false;
                    }
                });
            }
        };

        const closeDropdownOnOutsideClick = (event) => {
            const dropdown = document.querySelector('.custom-dropdown');
            if (dropdown && !event.target.classList.value.includes('dropdown-toggle') && !event.target.classList.value.includes('disabled')) {
                isDropdownOpen.value = false;
            }
        };

        onMounted(() => {
            document.addEventListener('click', closeDropdownOnOutsideClick);
            // Register the dropdown when it is mounted
            openDropdowns.push(instance.proxy);
        });

        onUnmounted(() => {
            document.removeEventListener('click', closeDropdownOnOutsideClick);
            // Unregister the dropdown when it is about to be destroyed
            const index = openDropdowns.indexOf(instance.proxy);
            if (index !== -1) {
                openDropdowns.splice(index, 1);
            }
        });

        return { 
            isDropdownOpen, 
            selectedOption, 
            toggleDropdown,
        };
    }
});
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/components/atoms/SfChevron.scss";
@import "~/components/customSFUI/shared/styles/components/molecules/FtSelect2/FtSelect2.scss";
</style>
  