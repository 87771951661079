<template>
  <li 
  :value="value"
  tabindex="0"
  @click="e => !disabled && optionClick(value, label)"
  :class="{ disabled: disabled, selected: selected }"
  >
      <slot />
  </li>
</template>

<script>
export default {
  name: "FtSelectOption",
  props: {
    value: {
      type: [String, Number, Object],
      default: "",
    },
    label: {
      type: [String, Number, Object],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    optionClick(value, label) {
      this.$parent.$emit("toggle", { value, label });
    },
  },
};
</script>
  